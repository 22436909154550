import React from "react"
import { useAuth } from "../../context/AuthContext"

const Profile = () => {
  const { isSignedIn, userProfile, authIsReady } = useAuth()

  if (!authIsReady) {
    return null
  }

  return (
    <div>
      <h2>My Profile</h2>
      {isSignedIn ? <p>yes</p> : <p>no</p>}
      <p>{JSON.stringify(userProfile)}</p>
    </div>
  )
}

export default Profile
