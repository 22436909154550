import React from "react"
import { Router } from "@reach/router"
import Layout from "../../components/layout"
import Profile from "../../components/app/Profile"
import Default from "../../components/app/Default"

const App = () => {
  return (
    <Layout>
      <Router basepath="/app">
        <Profile path="/profile" />
        <Default path="/" />
      </Router>
    </Layout>
  )
}

export default App
