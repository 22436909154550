import React from "react"

const Default = () => {
  return (
    <div>
      <h2>Default page</h2>
    </div>
  )
}

export default Default
